import {Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {LogService} from '../../services/log/log.service';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'iv-download',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadComponent {

  link: string | null = null;
  expiry: number = 0;
  Date: any;

  constructor(private route: ActivatedRoute, private logService: LogService, private router: Router,) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
          this.Date = Date.now() / 1000;
          this.link = atob(params['url']);
          this.expiry = params['expiry'];
        }
      );
  }

  download() {
    this.logService.track("bulk_file_downloaded", false,{
    });

    this.logService.logPendo('Bulk Download', {});
  }

  public routeToDashboard(): void {
    this.router.navigate(['/dashboard'], {});
  }
}

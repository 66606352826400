<!--<div class="sidebar">-->
  <header>
    <span>Chat History</span>
    <button (click)="closeSidebar()" class="close">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <section>
    <ng-container *ngIf="history == {}">
      <iv-inline-loading text="Loading chat history..." [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '30px', height: '30px'}"></iv-inline-loading>
    </ng-container>
    <ng-container *ngIf="history == null">
      <h1>No history available.</h1>
    </ng-container>
    <ng-container *ngIf="history != null">
      <ng-container *ngFor="let key of history.legend">
        <ng-container *ngIf="history[key].length">
          <mat-expansion-panel [expanded]="(activePanel === key)">
            <mat-expansion-panel-header (click)="setActivePanel(key)">
              <mat-panel-title>
                {{key === 'last7days' ? 'Past 7 Days' : key === 'last30days' ? 'Past 30 Days' : key}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
              <ng-container *ngFor="let rec of history[key]; trackBy: getConversationId">
                <li (click)="loadConversation(rec.conversation_id)" [class.active]="rec.conversation_id === selectedConversation?.conversation_id">
                  {{rec.title}}<br/>{{rec.history.localTimestamp}}
                </li>
              </ng-container>
            </ul>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!historyLoading">
        <div class="loaded-count">
          <span>Loaded {{history.count}} of {{history.total}}</span>
          <button *ngIf="history.count !== history.total" type="button" (click)="loadMoreChatbotHistory()">Load More</button>
        </div>
      </ng-container>
      <ng-container *ngIf="historyLoading">
        <iv-inline-loading text="Loading more history..." [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '30px', height: '30px'}"></iv-inline-loading>
      </ng-container>
    </ng-container>
  </section>
<!--</div>-->


// stores constants that are required across classes
export class Constants {

  // azure stores defined for each 'pane'
  static readonly ReportsStores: string[] = ['Reports', 'OperatorProfiles', 'Downloads', 'Bookmarks', 'ReportsNew'];
  static readonly LivefeedStores: string[] = ['LiveFeedAll', 'News', 'GlobalScouting', 'AnalystTakes', 'LiveFeedNew'];
  static readonly ResearchSpotlightStores: string[] = ['ResearchSpotlight'];
  static readonly ChatbotFiltersStores: string[] = ['InstantAnalyst'];

  // command line parameters
  static readonly livefeedSavedFiltersUrl: string = 'lff';                  // load the app with a saved query url in the livefeed pane
  static readonly reportsSavedFiltersUrl: string = 'rf';                    // load the app with a saved query url in the reports pane
  static readonly livefeedPaneQuery: string = 'lfq';                        // load the app with livefeed pane query (search) string and issue search
  static readonly reportsPaneQuery: string = 'rq';                          // load the app with reports pane query (search) string and issue search
  static readonly livefeedPaneTab: string = 'lftab';                        // load the app with livefeed pane set to a specific tab
  static readonly reportsPaneTab: string = 'rtab';                          // load the app with reports pane set to a specific tab
  static readonly chatbotQuery: string = 'iaq';                             // load the app with instant analyst tab open and prepopulate with a query

  // new articles
  static readonly newArticlesIntervalEnable: boolean = true;                // enable new articles interval updates (disabling also disables snackbar system, research spotlight, new articles counts/badges)
  static readonly newArticlesIntervalSeconds: number = (60);                // look for / calculate new articles count every N seconds
  static readonly newArticlesPublishedSeconds: number = (8*60*60);          // number of seconds (hours*minutes*seconds) since article published to appear on New tab(s)
  static readonly snackbarArticlesPublishedSeconds: number = (10*60);       // number of seconds (hours*minutes*seconds) since article published to appear in New snackbar(s);
                                                                            //   (NOTE: must be less than newArticlesPublishedSeconds as it extracts data from those results!)
  static readonly snackbarRotateSeconds: number = 30;                       // number of seconds (hours*minutes*seconds) to display a given article title before rotation
  static readonly snackbarTitleLength: number = 60;                         // maximum number of characters to show on snackbar for title

  // search

  static readonly suggestResults: boolean = true;                          // whether to enable ajax suggestions while typing into the search box
  static readonly suggestTotal: number = 8;                                 // the maximum number of suggestion results to return
  static readonly suggestDebounceMs: number = 250;                          // amount of time to wait between keystrokes before combining all and issuing suggest query
  static readonly useRestSearchApi: boolean = false;                        // use REST search api instead of javascript sdk (experimental)

  // misc
  static readonly activeFilterKey: string  = 'sf_active_filter';            // custom var on each store to save the active filter to
  static readonly publishedStartDate: string  = '1988-01-01';               // Date Range filter - what to set/reset starting date to
  static readonly facetsChipTextLimit: number = 80;                         // maximum number of characters to show on facets (filters) material chip
  static readonly facetsModifiedIgnoreDates: boolean = true;                // take published date range into account when determining if facets modified

  // messages
  static readonly subscriptionRequiredMessage: string = '**You do not have the necessary entitlement to view this content. Please contact your account representative or email EnergyAnalyticsSupport@enverus.com for assistance.';

  // chatbot
  static readonly chatbotStorageKey: string = 'chatbot_disclaimer';         // the key of the local storage item to track agreed disclosure
  static readonly chatbotDisclosureDate: string = '2024-02-12';             // date of the disclosure version; this must be updated when the disclosure is changed
                                                                            // in order to re-prompt users to agree (append a letter if multiple in same day, ie: b/c/d...)
  static readonly chatbotMaxChars: number = 1000;                           // the maximum number of characters that can be input in a question
  static readonly chatbotMinDate: string = '2018-01-01';                    // the minimum selectable date in the datepicker
  static readonly chatbotHistoryPageSize: number = 25;                      // the number of chatbot history items to load "per page" (progressive loading)
  static readonly chatbotMMPollRate: number = (60);                         // when maintenance mode active, check if cleared every N seconds

  // chatbot filters whitelist
  static readonly chatbotFiltersWhitelist: string[] = ["acl", "series", "publishedDate"];

  // research store key for saved filters
  // static readonly SavedFiltersStore: string = 'ActiveFilter';

}

import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {Subscription} from "rxjs";
import {DataService} from "../../../services/data/data.service";
import {SharedService} from "../../../services/shared/shared.service";
import {MatExpansionModule} from "@angular/material/expansion";
import {InlineLoadingComponent} from "../../inline-loading/inline-loading.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {IvauthService} from "../../../services/ivauth/ivauth.service";

@Component({
  selector: 'iv-chatbot-sidebar',
  standalone: true,
  imports: [CommonModule, InlineLoadingComponent, MatIconModule, MatExpansionModule, MatTooltipModule],
  templateUrl: './chatbot-sidebar.component.html',
  styleUrls: ['./chatbot-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatbotSidebarComponent implements OnDestroy, OnInit  {

  historySub: Subscription = Subscription.EMPTY;
  history: any = {};

  clearSelectConversationSub: Subscription = Subscription.EMPTY;

  chatbotConversationSub: Subscription = Subscription.EMPTY;
  public selectedConversation: any = null;

  activePanel: string = 'last7days';

  historyLoadingSub: Subscription = Subscription.EMPTY;
  public historyLoading: boolean = false;

  // selectedConversationId: string = '';

  constructor(
    private dataService: DataService,
    private sharedService: SharedService,
    private ivauthService: IvauthService,
  ) {
  }

  ngOnInit() {

    this.historySub = this.sharedService.chatbotHistory$.subscribe((history: any) => {
      // console.log(JSON.parse(JSON.stringify(history)));
      this.history = history;
    });

    this.chatbotConversationSub = this.sharedService.chatbotConversation$.subscribe((conversation: any) => {
      this.selectedConversation = conversation;
    });

    this.clearSelectConversationSub = this.sharedService.chatbotClearSelected$.subscribe((value: boolean) => {
      //this.selectedConversationId = '';
      this.sharedService.updateChatbotConversation(null);
    });

    this.historyLoadingSub = this.sharedService.chatbotHistoryLoading$.subscribe((status: boolean) => {
      this.historyLoading = status;
    });


  }

  public closeSidebar() {
    this.sharedService.updateChatbotHistoryOpen(false);
  }

  public setActivePanel(panel: string) {
    this.activePanel = panel;
  }

  public async loadConversation(conversation_id: string) {
    const _self = this;
    // console.log(`chatbot-sidebar::loadConversation(${conversation_id})`);
    if(!this.selectedConversation || (this.selectedConversation && (this.selectedConversation.conversation_id !== conversation_id))) {
      this.sharedService.updateChatbotConversationLoading(true);
      const accessToken: string = await this.ivauthService.getAccessToken();
      this.dataService.getConversationDetails(accessToken, conversation_id).then(function (conversation: any) {
        // console.log(JSON.parse(JSON.stringify(conversation)));
        _self.sharedService.updateChatbotConversation(conversation);
        _self.sharedService.updateChatbotConversationLoading(false);
      });
    } else {
      this.sharedService.updateChatbotConversation(null);
      this.sharedService.updateChatbotHistoryOpen(false);
    }
  }

  getConversationId(index: number, rec: any): string {
    return rec.conversation_id;
  }

  loadMoreChatbotHistory(): void {
    this.sharedService.loadMoreChatbotHistory(true);
  }

  ngOnDestroy() {
    if(this.historySub !== Subscription.EMPTY) {
      this.historySub.unsubscribe();
    }
    if(this.chatbotConversationSub !== Subscription.EMPTY) {
      this.chatbotConversationSub.unsubscribe();
    }
    if(this.clearSelectConversationSub !== Subscription.EMPTY) {
      this.clearSelectConversationSub.unsubscribe();
    }
    if(this.historyLoadingSub !== Subscription.EMPTY) {
      this.historyLoadingSub.unsubscribe();
    }
  }

}

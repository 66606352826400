import {Component, Input} from '@angular/core';
import {AzureSearchService} from '../../../../services/azuresearch/azuresearch.service';
import {SearchService} from '../../../../services/search/search.service';
import {CommonModule} from '@angular/common';
import {MatChipsModule} from '@angular/material/chips';
import {UserService} from '../../../../services/user/user.service';

@Component({
  selector: 'iv-intelligence-type',
  templateUrl: './intelligence-type.component.html',
  styleUrls: ['./intelligence-type.component.scss'],
  imports: [CommonModule, MatChipsModule],
  standalone: true
})
export class IntelligenceTypeComponent {
  // Makes sense to disconnect this from the filter list as filters could be added / removed at any time - should add something to the Chat API that returns this. Not to mention the active flag is getting set based on the active selection
  filterOptionList: Array<any> = [
    {
      name: 'Oil & Gas Research',
      acl: 'acl:oil-and-gas-research',
      active: false,
    },
    {
      name: 'Energy Transition Navigator',
      acl: 'acl:etr-lite',
      active: false,
    },
    {
      name: 'Energy Transition Research',
      acl: 'acl:energy-transition-research',
      active: false,
    },
    {
      name: 'Global Scout',
      acl: 'acl:global-scout',
      active: false,
    },
    {
      name: 'Publications',
      acl: 'acl:intelligence-publications',
      active: false,
    },
    {
      name: 'Activity Reports',
      acl: 'acl:activity-reports',
      active: false,
    },
  ];

  constructor(
    private azureSearchService: AzureSearchService,
    private searchService: SearchService,
    private userService: UserService
  ) {
    const user = this.userService.getUserPermissions();
    for (const filter of this.filterOptionList) {
      if (user.permissions.includes(filter.acl)) {
        filter.active = true;
      }
    }

    // this.searchService.reportsAzss$.subscribe((store: any) => {
    //   this.filterOptionList = this.azureSearchService.get_subscription_filters_filters(store.collection);
    //   console.log(this.filterOptionList);
    // });
  }

  private _store: any;

  @Input() set store(store: any) {
    // this.filterOptionList = this.azureSearchService.get_subscription_filters_filters(store.collection);
    // this._store = store;
  }

  getSubscriptionKey(index: number, subscription: any): string {
    return subscription.key;
  }
}

import {Component, AfterViewInit, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {environment as ENV} from '../../../environments/environment';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

import {Subscription} from 'rxjs';

import {DataService} from '../../services/data/data.service';
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {SearchService} from '../../services/search/search.service';
import {UserService} from '../../services/user/user.service';

// import { ResearchStore } from "../../store/research-store/research.store";

import {PdftronComponent} from '../../features/pdftron/pdftron.component';
// import {ShareDialogComponent} from "../../features/share-dialog/share-dialog.component";

import {MapToIterablePipe} from '../../pipes/map-to-iterable/map-to-iterable.pipe';
import {MomentDatePipe} from '../../pipes/momentDate/momentDate.pipe';
import {SafeHtmlPipe} from '../../pipes/safe-html/safe-html.pipe';
import {MomentModule} from 'ngx-moment';
import {InlineLoadingComponent} from '../../features/inline-loading/inline-loading.component';
import {ShareArticleDialogComponent} from '../../dialogs/share-article-dialog/share-article-dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MediaComponent} from '../../features/media/media.component';
import {ContactAnalystDialogComponent} from '../../dialogs/contact-analyst-dialog/contact-analyst-dialog.component';
import {LogService} from '../../services/log/log.service';

@Component({
  selector: 'iv-research-detail',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MapToIterablePipe,
    MomentDatePipe,
    RouterModule,
    SafeHtmlPipe,
    PdftronComponent,
    MomentModule,
    InlineLoadingComponent,
    MatButtonToggleModule,
    MatMenuModule,
    MediaComponent
  ],
  templateUrl: './research-detail.component.html',
  styleUrls: ['./research-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers: [SearchService],
  host: {'[class.fullframe]': 'fullframe'}
})
export class ResearchDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  // this does not work when contained with *ngIf blocks
  //@ViewChild('pdfPreview', { static: false }) pdfPreview!: ElementRef;

  @ViewChildren('pdfPreview') pdfPreviewList!: QueryList<ElementRef>;

  readonly tagIds: Array<string> = ['category', 'subCategory', 'companies', 'stockTickers', 'countries', 'regions', 'basins', 'plays', 'intervals', 'keywords'];

  // main.service: controls main layout mode
  researchDetailsDocIdSub: Subscription = Subscription.EMPTY;
  researchDetailsDocId: string = '';

  researchDetailsDocSub: Subscription = Subscription.EMPTY;
  researchDetailsDoc: any = null;

  articleReadTime = 0;

  q: string = '';
  page: number = 1;

  date: Date = new Date();
  document: string = '';
  downloadlink: any;
  pdfTronOptions: any;
  fullframe = false;
  videoSrc: any = null;
  PDFDeepLinkPage: number = 1;

  queuedDownloads: any = null;
  queuedDownloadsCount: number = 0;
  downloadQueueTooltip: string = '';
  showBulkDownloadMsg: boolean = false;
  enverusUser: boolean = false;

  currentProtocol: string = '';
  currentDomain: string = '';
  currentPort: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public dashboardService: DashboardService,
    public searchService: SearchService,
    public userService: UserService,
    private logService: LogService
  ) {
  }

  ngOnInit(): void {

    this.activatedRoute.data.subscribe(
      ({metadata}) => {
        this.researchDetailsDocId = metadata.result.id;
        if(metadata.result.files.hasOwnProperty('attachments')) {
          metadata.result.files.attachments.sort((a: any, b: any) => {
            let filenameA = a.filename.toUpperCase(); // ignore upper and lowercase
            let filenameB = b.filename.toUpperCase(); // ignore upper and lowercase
            if (filenameA < filenameB) {
              return -1;
            }
            if (filenameA > filenameB) {
              return 1;
            }
            return 0; // filenames are equal
          });
        }
        this.researchDetailsDoc = metadata.result;
        this.logService.article = metadata.result;
        this.getRouteData();
        this.combineTags(this.researchDetailsDoc);
        if (this.researchDetailsDoc.files.documents.length > 0 && this.researchDetailsDoc.files.documents[0].mimeType === 'application/pdf') {
          this.previewDocument();
        }

        this.articleReadTime = Math.ceil(this.countWords(this.researchDetailsDoc.descriptionText) / 238);
      });

    if (this.activatedRoute.snapshot.queryParams['pdfPage']) {
      if (!isNaN(this.activatedRoute.snapshot.queryParams['pdfPage'])) {
        this.PDFDeepLinkPage = parseInt(this.activatedRoute.snapshot.queryParams['pdfPage']);
      }
    }

    const profile = this.userService.getUserProfile();
    this.enverusUser = (profile && profile.app_metadata.organization && profile.app_metadata.organization.domain === 'enverus.com');

    this.q = this.searchService.getQuery('Reports');  // BUG: hardcoded - fix this!
    this.page = this.searchService.getPage('Reports');  // BUG: hardcoded - fix this!
  }

  ngAfterViewInit() {
    if (this.PDFDeepLinkPage === 1) {
      const el = document.getElementById(this.activatedRoute.snapshot.params['section']);
      if (el) {
        el.scrollIntoView();
      } else {
        document.getElementsByTagName('iv-research-detail')[0].scrollIntoView();
      }
    }
  }

  public toggleFullFrame() {
    this.fullframe = !this.fullframe;
  }

  public showPdfReadTime(event: string) {
    let words = this.countWords(event);
    if (event.includes('Disclosure Statement')) {
      words = words - 1130;
    }
    this.articleReadTime = Math.ceil(words / 238);
  }

  public previewDocument() {
    this.dataService.getDocumentPreview(this.researchDetailsDocId)
      .subscribe(data => {
        if (data.url && data.url.azure_url) {
          // this.pdfjs.pdfSrc = encodeURIComponent(data.url.azure_url);
          // this.pdfjs.refresh();
          this.document = data.url.azure_url;
          this.setPdfTronOptions();
          // this.cdr.detectChanges();

          this.logService.track('pdf_previewed', true, {
            article_filename: data.url.azure_url_filename
          });

          this.logService.logPendo('PDF Preview', {
            id: data.result.id,
            key: data.result.key,
            title: data.result.title,
            series: data.result.series,
            type: data.result.projectType,
            collection: data.result.collection,
            primaryAuthor: (data.result.primaryAuthor) ? (data.result.primaryAuthor.displayName) : '',
            authors: data.result.authors,
            publishedDate: data.result.publishedDate,
            filename: data.url.azure_url_filename
          });

        }
      }, err => {
      });
  }

  public downloadDocument(preview = false) {
    this.dataService.getDocument(this.researchDetailsDocId)
      .subscribe(data => {
        if (data.url && data.url.azure_url) {
          this.downloadlink = document.createElement('A');
          this.downloadlink.href = data.url.azure_url;
          // this.downloadlink.download = data.url.azure_url_filename;
          document.body.appendChild(this.downloadlink);
          this.downloadlink.click();
          document.body.removeChild(this.downloadlink);

          this.logService.track('pdf_downloaded', true, {
            article_filename: data.url.azure_url_filename
          });

          this.logService.logPendo('PDF Download', {
            id: data.result.id,
            key: data.result.key,
            title: data.result.title,
            series: data.result.series,
            type: data.result.projectType,
            collection: data.result.collection,
            primaryAuthor: (data.result.primaryAuthor) ? (data.result.primaryAuthor.displayName) : '',
            authors: data.result.authors,
            publishedDate: data.result.publishedDate,
            filename: data.url.azure_url_filename
          });

        }
      }, err => {
      });
  }

  public showAnalystButton() {
    const researchAcls = ['acl:morning-energy', 'acl:na-onshore-research', 'acl:power-research', 'acl:global-research', 'acl:macro-research'];
    const acl = this.researchDetailsDoc.acl;
    return researchAcls.some(i => acl.includes(i));
  }

  public contactAnalyst($event: any) {
    const dialogRef = this.dialog.open(ContactAnalystDialogComponent, {
      panelClass: 'iv-contact-analyst-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: this.researchDetailsDoc
    });
  }

  public downloadAttachment(id: any) {
    this.dataService.getAttachments(this.researchDetailsDocId)
      .subscribe(data => {
        if (data.url.length !== 0) {
          let file = data.url.filter((x: any) => x.azure_url_filename === id)[0].azure_url;
          const filename = data.url.filter((x: any) => x.azure_url_filename === id)[0].azure_url_filename;
          this.downloadlink = document.createElement('A');
          this.downloadlink.href = file;
          this.downloadlink.download = data.url.filter((x: any) => x.azure_url_filename === id)[0].azure_url_filename;
          document.body.appendChild(this.downloadlink);
          this.downloadlink.click();
          document.body.removeChild(this.downloadlink);

          this.logService.track('attachment_downloaded', true, {
            article_filename: filename
          });

          this.logService.logPendo('Attachment Download', {
            id: data.result.id,
            key: data.result.key,
            title: data.result.title,
            series: data.result.series,
            type: data.result.projectType,
            collection: data.result.collection,
            primaryAuthor: (data.result.primaryAuthor) ? (data.result.primaryAuthor.displayName) : '',
            authors: data.result.authors,
            publishedDate: data.result.publishedDate,
            filename: filename
          });

          this.downloadQueueSize();
        }
      }, err => {
      });
  }

  public toggleFavorite(item: any) {
    this.userService.toggleFavorite(item);
  }

  public isFavorite(id: any) {
    return this.userService.isFavorite(id);
  }

  public queueAll($event: any) {
    this.queuedDownloads = [];
    this.showBulkDownloadMsg = false;
    this.downloadQueueTooltip = '';
    if ($event.checked) {
      for (const result of this.researchDetailsDoc.files.attachments) {             // CHANGED THIS!

        // REMAP KEYS
        // key names - from: 'to'
        const keysMap = {
          path: 'filepath'
        };
        let resultRemap: any = this.renameKeys(keysMap, result);

        // ADD IN ANY MISSING DATA
        if (this.researchDetailsDoc.hasOwnProperty('id')) {
          resultRemap['parentId'] = this.researchDetailsDoc.id;
        }
        // filepath is expected to be base64 encoded
        resultRemap['filepath'] = btoa(resultRemap['filepath']);

        this.queuedDownloads.push(resultRemap);
      }
    }
    this.createTooltip();
    this.downloadQueueSize();
    // this.cdr.detectChanges();
  }

  public toggleQueued(result: any) {
    if (!this.queuedDownloads) {
      this.queuedDownloads = [];
    }

    // REMAP KEYS
    // key names - from: 'to'
    const keysMap = {
      path: 'filepath'
    };
    let resultRemap: any = this.renameKeys(keysMap, result);

    // ADD IN ANY MISSING DATA
    if (this.researchDetailsDoc.hasOwnProperty('id')) {
      resultRemap['parentId'] = this.researchDetailsDoc.id;
    }
    // filepath is expected to be base64 encoded
    resultRemap['filepath'] = btoa(resultRemap['filepath']);

    if (!this.queuedDownloads.find((qd: any) => qd.id == resultRemap.id)) {
      this.queuedDownloads.push(resultRemap);
    } else {
      let index = this.queuedDownloads.findIndex((qd: any) => qd.id == resultRemap.id);
      this.queuedDownloads.splice(index, 1);
    }
    this.createTooltip();
    this.downloadQueueSize();
  }

  public isQueued(id: any) {
    if (!this.queuedDownloads) {
      this.queuedDownloads = [];
    }
    if (this.queuedDownloads.find((qd: any) => qd.id == id)) {
      return true;
    }
  }

  public downloadQueueSize() {
    if (!this.queuedDownloads) {
      this.queuedDownloads = [];
    }
    if (!this.queuedDownloads.length) {
      this.showBulkDownloadMsg = false;
    }
    this.queuedDownloadsCount = this.queuedDownloads.length;
  }

  public bulkDownloadQueue() {

    // if only 1 file queued, then shunt them directly to downloadAttachment() !
    if (this.queuedDownloads.length === 1) {
      this.downloadAttachment(this.queuedDownloads[0].filename);
      this.queuedDownloads = [];

      // otherwise proceed with the bulk download system
    } else {

      this.showBulkDownloadMsg = true;
      this.dataService.getBulkFiles(JSON.stringify(this.queuedDownloads)).then((results) => {

        let fileList: string[] = [];
        this.queuedDownloads.forEach(function (attachment: any) {
          if (attachment.hasOwnProperty('filename') && attachment.filename.length) {
            fileList.push(attachment.filename);
          }
        });

        this.logService.track('bulk_file_requested', true, {
          files_downloaded: fileList,
          multi_article: false
        });
      });

    }

  }

  public clearDownloadQueue() {
    this.queuedDownloads = [];
    this.downloadQueueSize();
    this.downloadQueueTooltip = '';
  }

  public routeToDashboard(): void {
    this.router.navigate(['/dashboard'], {});
    // this.router.navigate(['/dashboard'], {queryParams: {page: this.page}} ); // q: q,
  }

  ngOnDestroy() {
    this.researchDetailsDocIdSub.unsubscribe();
    this.researchDetailsDocSub.unsubscribe();
    this.logService.article = null;
  }

  public shareEmail(actions: any) {
    const dialogRef = this.dialog.open(ShareArticleDialogComponent, {
      panelClass: 'iv-share-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: this.researchDetailsDoc
    });
  }

  // https://www.linkedin.com/help/linkedin/answer/a521928/make-your-website-shareable-on-linkedin
  shareLinkedIn(event: Event): void {
    event.preventDefault();

    this.currentProtocol = window.location.protocol;
    this.currentDomain = window.location.hostname;
    this.currentPort = window.location.port;

    this.dataService.publishSummary(this.researchDetailsDocId).then(result => {
      let targetPath: string = `${ENV.SSR.prependPath}/summary/livefeed/${result.summarytoken}`;
      console.log(targetPath);
      let url: string = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(targetPath)}`;
      window.open(url, '_blank');

      this.logService.track('live_feed_shared_linkedin', true, {});

      this.logService.logPendo('Share Article LinkedIn', {
        id: this.researchDetailsDoc.id,
        key: this.researchDetailsDoc.key,
        title: this.researchDetailsDoc.title,
        series: this.researchDetailsDoc.series,
        type: this.researchDetailsDoc.projectType,
        collection: this.researchDetailsDoc.collection,
        primaryAuthor: (this.researchDetailsDoc.primaryAuthor) ? (this.researchDetailsDoc.primaryAuthor.displayName) : '',
        authors: this.researchDetailsDoc.authors,
        publishedDate: this.researchDetailsDoc.publishedDate
      });

    });
  }

  // https://twitter.com/intent/tweet?url=https%3A%2F%2Fngx-sharebuttons.netlify.app%2F&text=Simple%2C%20lightweight%2C%20customizable%20share%20buttons&via=MurhafSousli
  shareTwitterX(event: Event): void {
    event.preventDefault();

    this.currentProtocol = window.location.protocol;
    this.currentDomain = window.location.hostname;
    this.currentPort = window.location.port;

    this.dataService.publishSummary(this.researchDetailsDocId).then(result => {
      let targetPath: string = `${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' && this.currentPort.length ? ':' + this.currentPort : ''}/summary/livefeed/${result.summarytoken}`;
      let url: string = `https://twitter.com/intent/tweet?url=${encodeURIComponent(targetPath)}&text=${encodeURIComponent(this.researchDetailsDoc.title)}`;
      window.open(url, '_blank');
    });
  }

  private setPdfTronOptions() {
    let options = <any>{};
    // if (this.downloadButton > 0) {
    //   options.download = true;
    // }
    if (this.q && this.q !== '' && this.q.replace(/"/g, '') !== this.researchDetailsDoc.title) {
      options.query = `"${this.q}"`;
    }

    options.page = this.PDFDeepLinkPage;

    this.pdfTronOptions = options;
  }

  //TODO: Fix at data source - requires changes to RSEG publisher and existing data
  private combineTags(data: any) {
    let tagFound = false;
    let tags: any = {
      solutionSet: {title: 'Solution Set', value: null},
      category: {title: 'Category', value: null},
      subCategory: {title: 'Sub Category', value: null},
      companies: {title: 'Companies', value: null},
      stockTickers: {title: 'Stock Tickers', value: null},
      countries: {title: 'Countries', value: null},
      regions: {title: 'Regions', value: null},
      basins: {title: 'Basins', value: null},
      plays: {title: 'Plays', value: null},
      intervals: {title: 'Intervals', value: null},
      keywords: {title: 'Keywords', value: null},
    };

    Object.keys(data).map(k => {
      if (this.tagIds.includes(k)) {
        tagFound = true;
        tags[k].value = data[k];
        delete data[k];
      }
    });
    data.tags = tagFound ? tags : {};
    return data.tags;
  }

  private countWords(s: string) {
    s = s.replace(/\n/g, ' '); // newlines to space
    s = s.replace(/(^\s*)|(\s*$)/gi, ''); // remove spaces from start + end
    s = s.replace(/[ ]{2,}/gi, ' '); // 2 or more spaces to 1
    return s.split(' ').length;
  }

  private getRouteData() {
    const data = this.route.snapshot.data;
    if (data['metadata'].result) {
      // this.titleService.setTitle(data['metadata'].result.title + ' | Data Driven Intelligence');
      if (data['metadata'].result.description) {
        const token = this.userService.getUserPermissions().ectoken;
        let regex = /(https:\/\/assets.intelligence.enverus.dev\/rsresearch-assets|https:\/\/assets.intelligence.enverus.com\/rsresearch-assets)(.*?)"/gi;
        const str = data['metadata'].result.description;
        const subst = `$1$2?a=${token}"`;
        const result = str.replace(regex, subst);
        this.videoSrc = null;
        data['metadata'].result.description = this.replaceVideo(result);
        data['metadata'].result.description = result;
      }

      // this.combineTags(data['metadata'].result);
      // this.metadata = data['metadata'].result;

      // this.userService.addRecentlyViewed(this.metadata);

      // if (this.metadata.files.documents.length > 0 && this.metadata.files.documents[0].mimeType === 'application/pdf') {
      //   this.previewDocument();
      // }

      this.logService.track('reports_viewed', true, {});

      this.logService.logPendo('Overview', {
        id: data['metadata'].result.id,
        key: data['metadata'].result.key,
        title: data['metadata'].result.title,
        series: data['metadata'].result.series,
        type: data['metadata'].result.projectType,
        collection: data['metadata'].result.collection,
        primaryAuthor: (data['metadata'].result.primaryAuthor) ? (data['metadata'].result.primaryAuthor.displayName) : '',
        authors: data['metadata'].result.authors,
        publishedDate: data['metadata'].result.publishedDate
      });


    }

  }

  private replaceVideo(str: string) {
    const regex = /<a.*?href="(.*\.mp4\?.*?)".*<\/a>/g;
    let m;

    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        if (groupIndex === 1) {
          this.videoSrc = match;
        }
      });
    }
    return str.replace(regex, '');
  }

  // public subscribeToFilter(event: any, filter: any){
  //   // placeholder
  //   if (event) {
  //     event.stopPropagation();
  //   }

  // https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fenverus.com&title=Enverus%20Article&summary=Article%20shared%20from%20Enverus

  private createTooltip() {
    let tooltip = `${this.queuedDownloads.length} Files Queued for Download:\n`;
    let total = 0;
    this.queuedDownloads.forEach(function (download: any) {
      let size = Math.round((download.size / 1000000) * 100) / 100;             // CHANGED THIS!
      total += size;             // CHANGED THIS!
      tooltip += `${download.filename} : ${size} MB\n`;             // CHANGED THIS!
    });
    total = Math.round(total * 100) / 100;
    tooltip += `Total uncompressed: ${total} MB`;
    this.downloadQueueTooltip = tooltip;
  }

  private renameKeys = (keysMap: any, obj: any) => {
    return Object.keys(obj).reduce((acc, key) => {
      const renamedObject = {
        [keysMap[key] || key]: obj[key]
      };
      return {
        ...acc,
        ...renamedObject
      };
    }, {});
  };

  private trackPrismLink(type: string) {

    this.logService.track('prism_button_selected', true, {
      vault_prism_type: type
    });

    this.logService.logPendo(`Prism Link - ${type}`, {
      id: this.researchDetailsDoc.id,
      key: this.researchDetailsDoc.key,
      title: this.researchDetailsDoc.title,
      series: this.researchDetailsDoc.series,
      type: this.researchDetailsDoc.projectType,
      collection: this.researchDetailsDoc.collection,
      primaryAuthor: (this.researchDetailsDoc.primaryAuthor) ? (this.researchDetailsDoc.primaryAuthor.displayName) : '',
      authors: this.researchDetailsDoc.authors,
      publishedDate: this.researchDetailsDoc.publishedDate
    });

  }
}

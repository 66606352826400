<div class="iv-chatbot-message__wrapper" [ngClass]="{
    'chatterbot': isChatterBotAuthor,
    'user': !isChatterBotAuthor,
    'creative' : conversationStyle.value === CONVERSATION_STYLE.CREATIVE,
    'balanced' : conversationStyle.value === CONVERSATION_STYLE.BALANCED,
    'precise' : conversationStyle.value === CONVERSATION_STYLE.PRECISE,
  }">
  <img *ngIf="isChatterBotAuthor"
        alt="Enverus Logo"
        ngSrc="/assets/icons/Enverus_GenAI_Icon-03%201.svg"
        class="iv-chatbot-message__chatbot-icon"
        height="100" width="100"/>
  <div [ngClass]="errorStatusCode > 0 ? 'iv-chatbot-message__error-body' : 'iv-chatbot-message__text-body'">
    <span>{{ messageText }}<ng-container *ngTemplateOutlet="loader"/>
      <ng-container *ngIf="!isChatterBotAuthor">
        <button (click)="showChatbotQueryShareDialog($event, messageText)"><mat-icon #icon>share</mat-icon></button>
      </ng-container>
    </span>
    <ng-container *ngTemplateOutlet="references"></ng-container>
    <ng-container *ngIf="isChatterBotAuthor">
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="!isChatterBotAuthor">
    <div class="iv-chatbot-message__icon-block">
      <mat-icon class="iv-chatbot-message__author-icon">person</mat-icon>
      <span class="iv-chatbot-message__msgtime">{{timestamp}}</span>
    </div>
  </ng-container>
</div>

<ng-template #references>
  <ng-content select="[references]"></ng-content>
</ng-template>

<ng-template #actions>
  <ng-content select="[actions]"></ng-content>
</ng-template>

<ng-template #loader>
  <ng-content select="[loader]"></ng-content>
</ng-template>

import {Component, Input} from '@angular/core';
import {Message} from "../chat.service";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {LogService} from 'src/app/services/log/log.service';

@Component({
  selector: 'iv-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
  imports: [ MatIconModule, CommonModule ],
  standalone: true
})
export class ReferencesComponent {
  referencesExpanded = false;
  additionalReferenceExpanded = false;
  prismReferenceExpanded = false;

  @Input() message: Message = {
    user_input: ''
  };

  constructor(private logService: LogService) {
  }

  logReference(url: string, title: string, pageNum: number | string, date: string) {

    this.logService.track("chatbot_reference_viewed", false,{
      "reference_date": date,
      "reference_page_num": pageNum,
      "reference_title": title,
      "reference_url": url,
    });

    this.logService.logPendo('ChatBot Reference Clicked', {
      referenceUrl: url,
      referenceTitle: title,
      referencePageNum: pageNum,
      referenceDate: date
    });

  }

}

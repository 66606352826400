import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {CONVERSATION_STYLE} from "./conversation-style";
import {LogService} from 'src/app/services/log/log.service';

@Component({
  selector: 'iv-hallucination',
  templateUrl: './hallucination.component.html',
  styleUrls: ['./hallucination.component.scss'],
  imports: [ CommonModule ],
  standalone: true
})
export class HallucinationComponent {
  @Input() conversationStyle = new FormControl<CONVERSATION_STYLE>(CONVERSATION_STYLE.BALANCED);
  @Input() allowedFeature = false;
  CONVERSATION_STYLE = CONVERSATION_STYLE;

  constructor(private logService: LogService) {

  }
  selectOption(conversationStyle: CONVERSATION_STYLE) {
    let cStyle: string = '';
    switch(conversationStyle) {
      case CONVERSATION_STYLE.CREATIVE:
        cStyle = 'Creative';
        break;
      case CONVERSATION_STYLE.BALANCED:
        cStyle = 'Balanced';
        break;
      case CONVERSATION_STYLE.PRECISE:
        cStyle = 'Precise';
        break;
    }

    this.logService.track("chatbot_conversation_style_selected", false,{
      conversation_style: cStyle
    });

    this.logService.logPendo('ChatBot Conversation Style', {
      key: cStyle,
    });

    this.conversationStyle.setValue(conversationStyle);
  }
}

import {Component} from '@angular/core';
import {Message} from "../chat.service";
import {environment as ENV} from "../../../../environments/environment";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LogService} from 'src/app/services/log/log.service';
import {IvauthService} from "../../../services/ivauth/ivauth.service";

@Component({
  selector: 'iv-rating-panel',
  templateUrl: './rating-panel.component.html',
  styleUrls: ['./rating-panel.component.scss'],
  imports: [MatIconModule, CommonModule, FormsModule],
  standalone: true
})
export class RatingPanelComponent {
  comment: string = '';
  selectedBubbles: Set<string> = new Set();
  isPositive = true;
  uniqueId: string = ''
  isOpen = false;
  message: Message = { user_input: '' };

  ratingOptions: {
    prompt_id: string;
    optionList: string[];
    isPositive: boolean;
  } = {
    prompt_id: '',
    optionList: [],
    isPositive: false,
  };

  constructor(
    private logService: LogService,
    private ivauthService: IvauthService,
  ) {}

  openPanel(message: Message, uniqueId: string, isPositive: boolean) {
    this.uniqueId = uniqueId;
    this.isPositive = isPositive;
    this.message = message;

    this.logService.track("chatbot_rated",false,{
      positive_response: isPositive
    });

    this.logService.logPendo('ChatBot Rating', {
      positive_response: isPositive,
    });

    const { prompt_id } = message as { prompt_id: string };
    const optionList = isPositive ?
      ['Correct', 'Easy to understand', 'Complete'] :
      ['Offensive / Unsafe', 'Not factually correct', 'Other'];
    this.ratingOptions = { prompt_id, isPositive, optionList };
    this.isOpen = true;
  }

  closePanel() {
    this.comment = '';
    this.selectedBubbles.clear();
    this.uniqueId = ''
    this.message = { user_input: '' };
    this.isPositive = false;
    this.isOpen = false;
  }

  async submitRating(message: Message) {
    const { prompt_id} = message;
    const body = {
      comment: this.comment,
      positive_response: this.isPositive,
      predefined_comments: Array.from(this.selectedBubbles),
      prompt_id,
      conversation_id: this.uniqueId,
    };

    this.logService.track("chatbot_rating_followup",false, {
      positive_response: this.isPositive,
      rating_selected: Array.from(this.selectedBubbles),
      comment_message: this.comment
    });

    this.logService.logPendo('ChatBot Rating Followup', {
      positive_response: this.isPositive,
      feedback: Array.from(this.selectedBubbles),
      comment: this.comment
    });

    const accessToken: string = await this.ivauthService.getAccessToken();

    fetch(ENV.API.chatBot.api+'/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Feedback submitted successfully:', data);
      })
      .catch((error) => {
        console.error('Error submitting feedback:', error);
      });
    this.closePanel();
  }

  toggleOptionSelected(option: string) {
    if(this.selectedBubbles.has(option)) {
      this.selectedBubbles.delete(option);
    } else {
      this.selectedBubbles.add(option);
    }
  }
}

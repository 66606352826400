import {ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Subscription} from "rxjs";

import {Constants} from "../../constants/constants";
import {AzssError} from "../../interface/azss";

import {AzureSearchService} from "../../services/azuresearch/azuresearch.service";
import {SearchService} from "../../services/search/search.service";
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'iv-subscription-filter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './subscription-filter.component.html',
  styleUrls: ['./subscription-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionFilterComponent implements OnDestroy, OnInit {
  @Input() pane: string | undefined;
  @Input() isGlobal: boolean | undefined;
  // @Input() store: any;
  // @Input() facet: any;

  store: any;  // AzssStore;
  storeAzssSubscription: Subscription = Subscription.EMPTY;

  private userPermissions: any = null;

  // public azureSearchService = inject( AzureSearchService );

  constructor(
    public azureSearchService: AzureSearchService,
    public searchService: SearchService,
    private userService: UserService,
    // private cdr: ChangeDetectorRef
  ) {
    if(this.isGlobal === undefined) {
      this.isGlobal = false;
    }
  }

  ngOnInit(): void {

    switch(this.pane) {
      case 'Reports':
        this.storeAzssSubscription = this.searchService.reportsAzss$.subscribe((data: any) => {
          this.store = data;
        });
        break;
      case 'Livefeed':
        this.storeAzssSubscription = this.searchService.livefeedAzss$.subscribe((data: any) => {
          this.store = data;
        });
        break;
      case 'Chatbot':
        this.storeAzssSubscription = this.searchService.chatbotAzss$.subscribe((data: any) => {
          this.store = data;
        });
        break;
    }

    if((this.store !== undefined) && (this.store.collection === Constants.ChatbotFiltersStores[0])) {
      this.userPermissions = this.userService.getUserPermissions().permissions;
    }

  }

  filterSubscription($event: any, subscription: string) {
    if(!this.isGlobal) {
      this.searchService.ss_set_subscription(this.store.collection, subscription, true);
      this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
      }).catch((error: any) => {
      });
      // this.cdr.detectChanges();
      // if(!this.searchService.ignore_facets_changed_events) {
      //   this.searchService.ss_test_active_filter_changed();
      // }
    }
  }

  getSubscriptionKey(index: number, subscription: any): string {
    return subscription.key;
  }

  subscriptionAllowed(subscriptionKey: string): boolean {
    let isAllowed: boolean = true;
    if(this.store.collection === Constants.ChatbotFiltersStores[0]) {
      // if(subscriptionKey === 'my-subscriptions') {
      //   isAllowed = false;
      // }
    }
    return isAllowed;
  }

  public testPermissions(filterString: string) {
    let isAllowed: boolean = true;
    if(this.store.collection === Constants.ChatbotFiltersStores[0]) {
      const regex: RegExp = /'([^']+)'/g;
      let match;
      const acls: string[] = [];
      while (match = regex.exec(filterString)) {
        acls.push(match[1]);
      }
      isAllowed = acls.some((acl: string) => this.userPermissions.includes(acl));
    }
    return isAllowed;
  }

  ngOnDestroy(): void {
    if(this.storeAzssSubscription !== Subscription.EMPTY) {
      this.storeAzssSubscription.unsubscribe();
    }
  }

}

import { Injectable } from '@angular/core';
import {Subject, Observable, Observer, Subscription, from, switchMap} from 'rxjs';
//import { WebSocket } from "partysocket";
import ReconnectingWebSocket from './reconnecting-websocket';
import {SharedService} from "../../services/shared/shared.service";
import {map, take} from "rxjs/operators";
import {environment as ENV} from "../../../environments/environment";
import {IvauthService} from "../../services/ivauth/ivauth.service";

const CHAT_URL = ENV.API.chatBot.websocket;

// @Injectable()
@Injectable({providedIn: 'root'})
export class WebsocketService {

  private accessTokenSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private sharedService: SharedService,
    private ivauthService: IvauthService,
  ) {

    this.ws = this.sharedService.getWebSocket();
    if(this.ws === null) {
      this.connect(CHAT_URL);
    }

  }

  meSubject: Subscription = Subscription.EMPTY;
  msgEvent: MessageEvent<any> = null as any;

  private subject: Subject<MessageEvent> = null as any;  // | undefined;
  private ws: any;
  // private connectionInitialized: boolean = false;

  public connect(url: any): Subject<MessageEvent> {

    if(!this.sharedService.isChatbotMessageEventInitialized()) {
      // console.log(`*** websocket.service :: CREATING WEBSOCKET ***`);
      this.subject = this.create(url);
      // this.sharedService.updateChatbotMessageEvent(this.subject);
      this.sharedService.updateChatbotWebsocketClosed(false);
      // this.connectionInitialized = true;
    }
    // else {
    //   console.log(`*** websocket.service :: REUSING WEBSOCKET ***`);
    // }

    // if(this.msgEvent === null) {
    //     console.log(`websocket.service::connect() !this.subject ${this.subject}`);
    //     this.subject = this.create(url);
    //     console.log('Successfully connected: ' + url);
    // } else {
    //   console.log(`connect() re-using this.msgEvent`);
    // }

    return this.subject;
  }

  private create(url: any): Subject<MessageEvent> {
    const subject = new Subject<MessageEvent>();

    this.accessTokenSubscription = from(this.ivauthService.getAccessToken()).pipe(
      switchMap((authToken: string) => {

        const options = {
          connectionTimeout: 5000,
          maxRetries: 0,
          debug: false
        };
        // this.ws = new WebSocket(url, authToken, options);
        this.ws = new ReconnectingWebSocket(url, authToken, options);
        this.sharedService.setWebSocket(this.ws);

        this.ws.onerror = (err: any) => console.error({err});
        // this.ws.onclose = (evt: any) => console.log("closed", evt);
        // this.ws.onopen = (evt: any) => console.log("opened", evt);
        // this.ws.onopen = () => this.ws.send("ping");

        this.ws.addEventListener('open', (event: Event) => {
          // console.log('WebSocket connection opened', event);
          this.updateStatus();
        });

        this.ws.addEventListener('close', (event: CloseEvent) => {
          this.sharedService.updateChatbotWebsocketClosed(true);
          this.updateStatus();
          // console.log('WebSocket connection closed', event);
          // this.ws.reconnect();

          // console.log("addEventListener('close') WebSocket is closed. NO Attempt to reconnect.", event);

          // console.log("WebSocket is closed. NO Attempt to reconnect - instantiating a new websocket.");
          // this.sharedService.updateChatbotWebsocketClosed(true);
          // this.connect(CHAT_URL);

        });

        // this.ws.addEventListener('message', (event: MessageEvent) => {
        //   console.log('Message received from WebSocket', event);
        // });

        this.ws.addEventListener('message', (event: MessageEvent) => {
          // console.log('Message received from WebSocket', event.data);
          this.sharedService.updateChatbotMessageEvent(event);
          this.updateStatus();
        });

        this.ws.addEventListener('error', (event: Event) => {
          console.error('WebSocket error', event);
        });

        const observable = new Observable(
          (obs: Observer<MessageEvent>) => {
            this.ws.onmessage = obs.next.bind(obs);
            // this.ws.onerror = obs.error.bind(obs);
            // this.ws.onclose = obs.complete.bind(obs);
          }
        );

        const observer = {
          next: (data: unknown) => {
            // console.log("next: " + this.ws.readyState)
            this.ws.send(JSON.stringify(data));
            if (this.ws.readyState === WebSocket.CLOSED) {
              // console.log("WebSocket is closed. NO Attempt to reconnect.");

              // this.ws.reconnect()

              // console.log("WebSocket is closed. NO Attempt to reconnect - instantiating a new websocket.");
              // this.sharedService.updateChatbotWebsocketClosed(true);
              // this.connect(CHAT_URL);
            }
          },
        };

        const resultSubject = Subject.create(observer, observable);
        return new Observable<Subject<MessageEvent>>(subscriber => {
          subscriber.next(resultSubject);
          subscriber.complete();
        });

      })
    ).subscribe((resultSubject: Subject<MessageEvent>) => {
      resultSubject.subscribe(event => subject.next(event));
    });

    return subject;
    // return Subject.create(observer, observable);
  }

  public cleanupSubscriptions(): void {
    if (this.accessTokenSubscription !== Subscription.EMPTY) {
      this.accessTokenSubscription.unsubscribe();
      this.accessTokenSubscription = Subscription.EMPTY;
    }
  }

  // public send(data: string): void {
  //   if (this.ws && this.ws.readyState === WebSocket.OPEN) {
  //     this.ws.send(data);
  //   } else {
  //     this.sharedService.updateChatbotWebsocketClosed(true);
  //     console.log("send() WebSocket is closed - attempting reconnect...");
  //     this.connect(CHAT_URL);
  //     this.ws = this.sharedService.getWebSocket();
  //     console.log(this.ws);
  //     console.log(JSON.parse(JSON.stringify(this.ws.readyState)));
  //     if (this.ws && this.ws.readyState === WebSocket.OPEN) {
  //       this.ws.send(data);
  //     } else {
  //       console.log(this.ws);
  //       console.error('send() *FAILED* WebSocket could not be opened.');
  //     }
  //   }
  // }

  // Utility function to wait for a specific condition or timeout
  waitFor = (conditionFunction: () => boolean, timeoutMs: number) => {
    return new Promise((resolve, reject) => {
      const checkCondition = () => {
        if (conditionFunction()) {
          resolve(true);
        } else if ((timeoutMs -= 100) < 0) {
          reject(false);
        } else {
          setTimeout(checkCondition, 100);
        }
      };
      checkCondition();
    });
  };

  // Modified send function with retry logic
  public async send(data: string): Promise<void> {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(data);
    } else {
      this.sharedService.updateChatbotWebsocketClosed(true);
      // console.log("send() WebSocket is closed - attempting reconnect...");
      this.connect(CHAT_URL);
      this.ws = this.sharedService.getWebSocket();

      // Retry logic
      for (let attempt = 1; attempt <= 5; attempt++) {
        try {
          // Wait for WebSocket to be OPEN
          await this.waitFor(() => this.ws.readyState === WebSocket.OPEN, 3000);
          this.ws.send(data);
          return; // Exit if successful
        } catch (error) {
          // console.log(`Attempt ${attempt}: Waiting for WebSocket to open...`);
        }
      }

      // If we reach here, WebSocket did not open after retries
      // console.log(this.ws);
      console.error('send() *FAILED* WebSocket could not be opened.');

      this.sharedService.updateChatbotStatus('failed');
    }
  }

  public updateStatus(): void {
    if(this.ws) {
      switch(this.ws.readyState) {
        case 0:
          this.sharedService.updateChatbotStatus('connecting')
          break;
        case 1:
          this.sharedService.updateChatbotStatus('open')
          break;
        case 2:
          this.sharedService.updateChatbotStatus('closing')
          break;
        case 3:
          this.sharedService.updateChatbotStatus('closed')
          break;
        default:
          this.sharedService.updateChatbotStatus('unknown')
          break;
      }
    } else {
      this.sharedService.updateChatbotStatus('invalid')
    }
  }

}

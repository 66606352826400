import {ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../services/data/data.service';
import {UserService} from '../../services/user/user.service';
import {LogService} from '../../services/log/log.service';
import {MatIconModule} from "@angular/material/icon";

@Component({
  standalone: true,
  selector: 'iv-share-dialog',
  templateUrl: './share-article-dialog.component.html',
  styleUrls: ['./share-article-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule
  ]
})
export class ShareArticleDialogComponent {
  shareForm = new FormGroup({
    emails: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9._%+-]+@[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)(,\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9._%+-]+@[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+))*$/)
    ]),
    message: new FormControl(null)
  });

  error = false;
  success = false;
  emailList: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ShareArticleDialogComponent>, private dataService: DataService, private logService:LogService) {
  }

  get f() {
    return this.shareForm.controls;
  }

  onSubmit() {
    if (this.shareForm.valid) {
      this.emailList = this.shareForm.value.emails;
      this.dataService.share(this.data.id, this.shareForm.value).subscribe({
        next: (v) => {

          this.logService.track("live_feed_shared_email", true,{
            "recipient_email": this.emailList,
            "sender_message": this.shareForm.value.message,
          });

          this.logService.logPendo('Share Article Email', {
            id: this.data.id,
            key: this.data.key,
            title: this.data.title,
            series: this.data.series,
            type: this.data.projectType,
            collection: this.data.collection,
            primaryAuthor: (this.data.primaryAuthor) ? (this.data.primaryAuthor.displayName) : '',
            authors: this.data.authors,
            publishedDate: this.data.publishedDate,
            message: this.shareForm.value.message,
            emails: this.emailList
          });

          this.error = false;
          this.success = true;
        },
        error: (e) => {
          this.success = false;
          this.error = true;
        }
      });
    }
  }

  closeShareDialog() {
    this.dialogRef.close();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Subscription} from 'rxjs';

import {NgPipesModule} from 'ngx-pipes';

import {Constants} from "../../constants/constants";
import {AzureSearchService} from '../../services/azuresearch/azuresearch.service';
import {SearchService} from '../../services/search/search.service';

import {AzssStore} from '../../interface/azss';
import {Research} from '../../interface/research';
import {ResearchStore} from '../../store/research-store/research.store';
import {ReportsViewComponent} from '../../views/reports-view/reports-view.component';

import {RouterLink} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxSplideModule} from 'ngx-splide';
import {MomentModule} from 'ngx-moment';
import {LogService} from '../../services/log/log.service';

@Component({
  selector: 'iv-research-spotlight',
  standalone: true,
  imports: [
    CommonModule,
    NgPipesModule,
    ReportsViewComponent,
    RouterLink,
    MatTooltipModule,
    NgxSplideModule,
    MomentModule
  ],
  templateUrl: './research-spotlight.component.html',
  styleUrls: ['./research-spotlight.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ResearchStore],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResearchSpotlightComponent implements OnInit, OnDestroy {

  azss: AzssStore = {};
  azssSubscription: Subscription = Subscription.EMPTY;

  research: Research[] = [];
  researchSubscription: Subscription;
  selectedImageIndex = 0;

  subscriptionRequiredMessage: string = Constants.subscriptionRequiredMessage;

  private azureSearchService = inject(AzureSearchService);

  constructor(
    public searchService: SearchService,
    private cdr: ChangeDetectorRef,
    private logService:LogService
  ) {

    // this.searchService.init_stores(['ResearchSpotlight'] );
    // this.azureSearchService.update_search_parameters('ResearchSpotlight', {orderby: 'publishedDate desc', top: 3});

    this.researchSubscription = this.searchService.research$.subscribe((research) => {
      this.research = research;
    });

  }

  ngOnInit(): void {

    this.azssSubscription = this.searchService.researchSpotlightAzss$.subscribe((newAzss) => {
      this.azss = newAzss;
      this.cdr.detectChanges();
    });
    setInterval(() => {
      if (this.selectedImageIndex === 8) {
        this.selectedImageIndex = 0;
      } else {
        this.selectedImageIndex = this.selectedImageIndex + 1;
      }
    }, 8000);
  }

  logPendoEvent(article: any){

    this.logService.article = article;
    this.logService.track("spotlight_article_opened", true,
      { article_authors: article.authors },
      ["article_primary_author", "article_supporting_authors"]
    );

    this.logService.logPendo('Spotlight Article', {
      id: article.id,
      key: article.key,
      title: article.title,
      series: article.series,
      type: article.projectType,
      collection: article.collection,
      authors: article.authors,
      publishedDate: article.publishedDate
    });

  }

  ngOnDestroy() {
    this.researchSubscription.unsubscribe();
    this.azssSubscription.unsubscribe();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppModule } from './app.module';
//import { AuthModule } from '@auth0/auth0-angular';
import { AppComponent } from './app.component';
import { environment as ENV } from '../environments/environment';

// Auth0
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { CgwInterceptor } from './services/cgw/cgw.interceptor';
import { WidgetsInterceptor } from './services/widgets/widgets.interceptor';

@NgModule({
  declarations: [
    //   AppComponent,
  ],
  imports: [
    CommonModule,
    AppModule,
    AuthModule.forRoot({
      domain: ENV.AUTH_CONFIG.domain,
      clientId: ENV.AUTH_CONFIG.clientID,
      cacheLocation: ENV.AUTH_CONFIG.cacheLocation,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      authorizationParams: {
        audience: ENV.AUTH_CONFIG.audience,
        redirect_uri: ENV.AUTH_CONFIG.callbackUri,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${ENV.API.vaultProtected}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: ENV.AUTH_CONFIG.audience,
              },
            },
          },
          {
            uri: `${ENV.API.prismData}`,
            tokenOptions: {
              authorizationParams: {
                audience: ENV.AUTH_CONFIG.audience,
              },
            },
          },
        ],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CgwInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WidgetsInterceptor, multi: true },
    // provideHttpClient(
    //   withInterceptors([]),
    //   withInterceptorsFromDi()
    // ),
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppClientModule {}

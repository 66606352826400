import {Component, Input, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core'; // OnChanges,
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

import {Subscription} from "rxjs";

import { Constants } from "../../constants/constants";
const reportsStores: string[] = Constants.ReportsStores;
const livefeedStores: string[] = Constants.LivefeedStores;

import { AzureSearchService } from "../../services/azuresearch/azuresearch.service";
import { SearchService } from "../../services/search/search.service";
import { SharedService } from "../../services/shared/shared.service";

import { ActiveFilter, createEmptyActiveFilter } from "../../interface/activefilter";
import { Research } from "../../interface/research";

interface DateLookup {
  [key: string]: Date | null;
}

@Component({
  selector: 'iv-date-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateFilterComponent implements OnInit, OnDestroy {  // , OnChanges
  @Input() facet: any;
  @Input() storeKey: any;
  @Input() isGlobal: boolean | undefined;
  // @Input() activeFilter: ActiveFilter | undefined;

  // need to track per collection
  startDate: DateLookup = {};
  endDate: DateLookup = {};

  research: Research[] = [];
  researchItem: Research | undefined;
  researchSubscription: Subscription = Subscription.EMPTY;

  activeFilterSub: Subscription = Subscription.EMPTY;
  activeFilter: ActiveFilter = createEmptyActiveFilter();

  minDate: Date = new Date(Constants.chatbotMinDate);

  constructor(
    public azureSearchService: AzureSearchService,
    public searchService: SearchService,
    private sharedService: SharedService,
  ) {
    if(this.isGlobal === undefined) {
      this.isGlobal = false;
    }

  }

  ngOnInit() {

    if(this.storeKey === Constants.ChatbotFiltersStores[0]) {
      this.minDate = new Date(Constants.chatbotMinDate);
    } else {
      this.minDate = new Date(Constants.publishedStartDate);
    }

    if(this.facet.filterClause.length){

      // restores the range from facets! (on collection change, etc...)
      this.startDate[this.storeKey] = new Date(this.facet.filterLowerBound);
      this.endDate[this.storeKey] = new Date(this.facet.filterUpperBound);

    } else {
      if(this.storeKey !== Constants.ChatbotFiltersStores[0]) {
        this.startDate[this.storeKey] = this.minDate;
        this.endDate[this.storeKey] = new Date();
      }

    }

    // set callback to be run at end of clear_all_facets()
    let azssError: any;
    if((azssError = this.azureSearchService.clear_facets_processor_add(this.storeKey,
      'date-filter', this.date_filter_clear_facets.bind(this)))) {
    }

    this.researchSubscription = this.searchService.research$.subscribe((research) =>{
      this.research = research;
      this.researchItem = JSON.parse(JSON.stringify( this.research.find(rec => rec.activeStoreKey === this.storeKey) ));
    });

    if( reportsStores.indexOf(this.storeKey) >= 0 ) {
      this.activeFilterSub = this.sharedService.reportsActiveFilter$.subscribe( af=> {
        this.activeFilter = af;
        // if(this.activeFilter && this.activeFilter.hasOwnProperty('facetsDiff') && this.activeFilter.facetsDiff &&
        // this.activeFilter.facetsDiff.hasOwnProperty('facets') && Array.isArray(this.activeFilter.facetsDiff['facets'])) {
        //   const publishedDateFacet = this.activeFilter.facetsDiff['facets'].find(
        //     (facet: any) => facet.key === 'publishedDate'
        //   );
        //   if (publishedDateFacet) {
        //     this.startDate[this.storeKey] = new Date(publishedDateFacet.filterLowerBound);
        //     this.endDate[this.storeKey] = new Date(publishedDateFacet.filterUpperBound);
        //   }
        // }
      });
    } else {
      if (livefeedStores.indexOf(this.storeKey) >= 0) {
        this.activeFilterSub = this.sharedService.livefeedActiveFilter$.subscribe( af=> {
          this.activeFilter = af;
          // this.startDate[this.storeKey] = new Date(this.facet.filterLowerBound);
          // this.endDate[this.storeKey] = new Date(this.facet.filterUpperBound);
        });
      }
    }

    //this.azureSearchService.dump_stores( this.searchService.ss_active_storeKey );
  }

  // ngOnChanges(changes: SimpleChanges) {
  //
  //   if (changes['activeFilter']) {
  //     if(this.activeFilter) {
  //     }
  //   }
  //
  // }

  selectDateRange(days: string) {

    if(this.isGlobal !== true) {

      switch (days) {
        case 'today': {
          this.startDate[this.storeKey] = new Date();
          if (this.activeFilter && !this.activeFilter.dateRangeEdited) {
            // this.activeFilter.dateRangeEdited = true;
            // this.sharedService.updateActiveFilter(this.storeKey, this.activeFilter);
            this.sharedService.updateActiveFilterParameter(this.storeKey, 'dateRangeEdited', true);
          }
          break;
        }
        case '7': {
          this.startDate[this.storeKey] = new Date(new Date().setDate(new Date().getDate() - 7));
          if (this.activeFilter && !this.activeFilter.dateRangeEdited) {
            // this.activeFilter.dateRangeEdited = true;
            // this.sharedService.updateActiveFilter(this.storeKey, this.activeFilter);
            this.sharedService.updateActiveFilterParameter(this.storeKey, 'dateRangeEdited', true);
          }
          break;
        }
        case '30': {
          this.startDate[this.storeKey] = new Date(new Date().setDate(new Date().getDate() - 30));
          if (this.activeFilter && !this.activeFilter.dateRangeEdited) {
            // this.activeFilter.dateRangeEdited = true;
            // this.sharedService.updateActiveFilter(this.storeKey, this.activeFilter);
            this.sharedService.updateActiveFilterParameter(this.storeKey, 'dateRangeEdited', true);
          }
          break;
        }
        case '90': {
          this.startDate[this.storeKey] = new Date(new Date().setDate(new Date().getDate() - 90));
          if (this.activeFilter && !this.activeFilter.dateRangeEdited) {
            // this.activeFilter.dateRangeEdited = true;
            // this.sharedService.updateActiveFilter(this.storeKey, this.activeFilter);
            this.sharedService.updateActiveFilterParameter(this.storeKey, 'dateRangeEdited', true);
          }
          break;
        }
        case 'all': {
          // this.startDate[this.storeKey] = new Date(Constants.publishedStartDate);
          this.startDate[this.storeKey] = this.minDate;
          if (this.activeFilter && this.activeFilter.dateRangeEdited) {
            // this.activeFilter.dateRangeEdited = false;
            // this.sharedService.updateActiveFilter(this.storeKey, this.activeFilter);
            this.sharedService.updateActiveFilterParameter(this.storeKey, 'dateRangeEdited', false);
          }
          break;
        }
        default: {
          break;
        }
      }
      this.endDate[this.storeKey] = new Date();

      // this will correct the all the date range parameters in the azss store
      this.searchService.ss_search_from_facet(this.storeKey, {type: 'RangeFacet', category: 'publishedDate'},
        {
          startDate: this.startDate[this.storeKey],
          endDate: this.endDate[this.storeKey]
        });

      // BUG: we now need to update the activeFilter facetsdiff when this completes!
      // this.sharedService.updateActiveFilterFacetsDiffParameter(this.storeKey, 'publishedDate',
      //   'filterLowerBound', this.startDate[this.storeKey]);
      // this.sharedService.updateActiveFilterFacetsDiffParameter(this.storeKey, 'publishedDate',
      //   'filterLowerBound', this.endDate[this.storeKey]);


      // this was used to detect if a filter (set of facets) has been changed
      // this.searchService.ss_test_active_filter_changed();  // TODO: restore this

    }

  }

  private date_filter_clear_facets() {
    // this.startDate[this.storeKey] = new Date( Constants.publishedStartDate );

    if(this.storeKey !== Constants.ChatbotFiltersStores[0]) {
      this.startDate[this.storeKey] = this.minDate;
      this.endDate[this.storeKey] = new Date();

    } else {
      this.startDate[this.storeKey] = null;
      this.endDate[this.storeKey] = null;

    }

    if(this.activeFilter) {
      // this.activeFilter.dateRangeEdited = false;
      // this.sharedService.updateActiveFilter(this.storeKey, this.activeFilter);
      this.sharedService.updateActiveFilterParameter(this.storeKey, 'dateRangeEdited', false);
    }

    // this was used to detect if a filter (set of facets) has been changed
    // this.searchService.ss_test_active_filter_changed();  // TODO: restore this
  }

  dateFilter = (date: Date): boolean => {
    return date >= this.minDate;
  };

  ngOnDestroy(): void {
    this.azureSearchService.clear_facets_processor_remove(this.storeKey, 'date-filter');
    this.researchSubscription.unsubscribe();
    if(this.activeFilterSub !== Subscription.EMPTY) {
      this.activeFilterSub.unsubscribe();
    }
  }

}

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TechUserAuthService } from 'src/app/features/widgets-area/widgets-data.service';

@Injectable()
export class WidgetsInterceptor implements HttpInterceptor {
  constructor(private techUserAuthService: TechUserAuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/sql')) {
      const modifiedReq = req.clone({
        setHeaders: {
          Authorization:
            'Bearer ' + sessionStorage.getItem('tech_access_token'),
        },
      });

      return next.handle(modifiedReq);
    } else {
      return next.handle(req);
    }
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment as ENV} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TechUserAuthService {
  constructor(private http: HttpClient) {
  }

  async getToken(): Promise<any> {
    const techUserExpiryDate = sessionStorage.getItem('tech_expires_at');
    let isTechTokenValid = true;
    if (techUserExpiryDate) {
      const expiryDate = new Date(techUserExpiryDate);
      if (expiryDate <= new Date()) {
        isTechTokenValid = false;
      }
    } else {
      isTechTokenValid = false;
    }
    if (!isTechTokenValid) {
      const response = await this.http
        .get<any>(ENV.API.vaultProtected + '/vault/widgetAuth')
        .toPromise();

      const expiresIn = response.result.auth.expires_in;
      const expiryDate = new Date(new Date().getTime() + expiresIn * 1000);
      sessionStorage.setItem(
        'tech_access_token',
        response.result.auth.access_token
      );
      sessionStorage.setItem('tech_expires_at', expiryDate.toISOString());
    }
  }
}
